/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import LoginFormInput from "../../../common/components/EditableText/LoginFormInput";
import { stateOptions } from "../../../common/constants/initialData";
import { editDemographicsOfPatient } from "../../../services/api.services";
import { validateField } from "../../../services/validations";
import { autoZip, getExistingFollowUp, getFollowUp, rescheduleProcedure, setGlobalLoading, setPatientSnapshotData } from "../../../store/actions";

let timer = null;

const Demographics = ({ handleContinue, ...restProps }) => {
  const resource = useSelector((state) => state.procedure.resource);
  const zipAutoData = useSelector((state) => state.common.zipAuto);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const dispatch = useDispatch();
  const [patient, setPatient] = useState({});
  const [enableError, setEnableError] = useState(false);
  const loading = useSelector((state) => state.procedure.loadingResource);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (resource) {
      setPatient(resource.patient);
      dispatch(rescheduleProcedure(resource.patient_id, resource?.id, {}));
      const newOption = {
        prev_date: moment(resource.appointment_date).format("MM/DD/YYYY"),
      };
      if (!restProps.isNotFollowUp) {
        dispatch(getFollowUp(resource.patient_id, resource?.id, newOption, false, "true"));
        dispatch(getExistingFollowUp(resource?.id));
      }
      setReset(true);
      setEnableError(true);
    }
  }, [resource]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingGlobal));
  }, [loading, loadingGlobal]);

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0) {
      if (zipAutoData?.success) {
        if (zipAutoData?.addresses && zipAutoData?.addresses[0]) {
          const address = zipAutoData?.addresses[0];
          if (!restProps.isAddForm) {
            const param = {
              ...patient,
              state: address.state_id,
              city: address.place,
              zipcode: patient?.zipcode,
            };
            setPatient(param);
            updatePatient(param);
            setReset(true);
          }
        }
      } else {
        if (!restProps.isAddForm) {
          const param = {
            ...patient,
            state: undefined,
            city: undefined,
            zipcode: patient?.zipcode,
          };
          setPatient(param);
          updatePatient(param);
          setReset(true);
        }
      }
    }
  }, [zipAutoData]);

  const onChangePatient = (type) => (value) => {
    if (type == "zipcode") {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(autoZip(value));
      }, 500);
      const newPatient = {
        ...patient,
        [type]: value,
      };
      setPatient(newPatient);
    } else {
      const newPatient = {
        ...patient,
        [type]: value,
      };
      setPatient(newPatient);
      if (!restProps.isAddForm) {
        reset && changeHandler(newPatient);
      }
    }
  };

  const onChangeAddress = (type) => (value) => {
    let address = "";
    if (value) {
      const splitedAddress = value.split(" ").map((addr) => {
        if (!addr || !addr.length) {
          return "";
        }
        if (addr.length < 2) {
          return addr.charAt(0).toUpperCase();
        }
        return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
      });
      address = splitedAddress.join(" ");
    }

    const newPatient = {
      ...patient,
      [type]: address,
    };
    setPatient(newPatient);
    setEnableError(false);
    if (!restProps.isAddForm) {
      reset && changeHandler(newPatient);
    }
  };

  const updatePatient = async (patient) => {
    const updatedPatient = { ...patient };
    updatedPatient["date_of_birth"] = patient["date_of_birth"] && moment(patient["date_of_birth"]).format("MM/DD/YYYY");
    updatedPatient["referral_date"] = patient?.referral_date && moment(patient?.referral_date).format("MM/DD/YYYY");
    updatedPatient["primary_care_provider_id"] = patient["primary_care_provider_id"] || patient?.pcp;
    setLoadingGlobal(true);
    try {
      await editDemographicsOfPatient(updatedPatient);
      await dispatch(setPatientSnapshotData("resource", patient));
      setLoadingGlobal(false);
    } catch (error) {
      setLoadingGlobal(false);
    }
  };

  const changeHandler = useCallback(
    debounce((patient) => updatePatient(patient), 400),
    []
  );

  const getProcedureValue = (resource) => {
    if (resource && resource.procedure_type) {
      if (resource.side) {
        return resource.side + " " + resource.procedure_type?.value;
      } else return resource.procedure_type?.value;
    } else if (resource && resource.angio_type) {
      if (resource.side) return resource.side + " " + resource.angio_type?.name;
      else return resource.angio_type?.name;
    } else if (resource && resource.ultrasound_type) {
      if (resource.side) {
        return resource.side + " " + resource.ultrasound_type?.name;
      } else return resource.ultrasound_type?.name;
    } else if (resource && resource.clinic_type) {
      if (resource.side) {
        return resource.side + " " + resource.clinic_type?.name;
      } else return resource.clinic_type?.name;
    }
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
      <React.Fragment>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"First Name"}
            value={patient?.first_name}
            handleChange={onChangePatient("first_name")}
            required={true}
            isError={validateField("text", patient?.first_name)}
            enableErrorText={enableError}
            helperText={"First Name is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Last Name"}
            value={patient?.last_name}
            handleChange={onChangePatient("last_name")}
            required={true}
            isError={validateField("text", patient?.last_name)}
            enableErrorText={enableError}
            helperText={"Last Name is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput isErrorTextSpacing={true} label={"ID"} value={patient?.id} disabled={true} />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Deceased"}
            handleChange={onChangePatient("deceased")}
            value={patient?.deceased}
            type={"check"}
            required={true}
            isError={validateField("text", patient?.deceased)}
            enableErrorText={enableError}
            helperText={"Deceased is Required!"}
            optionKey={"value"}
            valueKey={"name"}
            options={[
              { value: "yes", name: "Yes" },
              { value: "no", name: "No" },
            ]}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"DOB"}
            value={patient?.date_of_birth ? moment(patient?.date_of_birth).format("MM/DD/YYYY") : undefined}
            handleChange={onChangePatient("date_of_birth")}
            required={true}
            mask={"date"}
            isError={validateField("date", patient?.date_of_birth)}
            enableErrorText={enableError}
            helperText={"Birthday should be MM/DD/YYYY!"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Phone No"}
            value={patient?.phone_number}
            handleChange={onChangePatient("phone_number")}
            required={true}
            isError={validateField("phone_number", patient?.phone_number)}
            enableErrorText={enableError}
            helperText={"Phone Number should be (XXX) XXX-XXXX format"}
            mask={"phone"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Phone No"}
            value={patient?.secondary_phone}
            handleChange={onChangePatient("secondary_phone")}
            mask={"phone"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput isErrorTextSpacing={true} label={"E-Mail"} value={patient?.email} handleChange={onChangePatient("email")} />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Address"}
            value={patient?.address}
            handleChange={onChangeAddress("address")}
            required={true}
            isError={validateField("text", patient?.address)}
            enableErrorText={enableError}
            helperText={"Address is required"}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableFormInput isErrorTextSpacing={true} label={"Apt"} value={patient?.apt_number} handleChange={onChangePatient("apt_number")} />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"State"}
            value={patient?.state || undefined}
            handleChange={onChangePatient("state")}
            type={"check"}
            valueKey={"value"}
            optionKey={"id"}
            options={stateOptions || []}
            required={true}
            isError={validateField("text", patient?.state)}
            enableErrorText={enableError}
            helperText={"State is required"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"City"}
            value={patient?.city}
            handleChange={onChangePatient("city")}
            required={true}
            isError={validateField("text", patient?.city)}
            enableErrorText={enableError}
            helperText={"City is required"}
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Zip Code"}
            value={patient?.zipcode}
            handleChange={onChangePatient("zipcode")}
            required={true}
            isError={validateField("text", patient?.zipcode)}
            enableErrorText={enableError}
            helperText={"Zip Code is required"}
          />
        </div>
        <div className="resourceInfo d-flex" style={{ pointerEvents: "none" }}>
          <LoginFormInput
            label={"Procedure"}
            value={getProcedureValue(resource)}
            disabled={resource && resource.procedure_type && resource.procedure_type?.value ? false : true}
            noEditable
          />
        </div>
        <div className="resourceInfo d-flex" style={{ pointerEvents: "none" }}>
          <LoginFormInput
            label={"Location"}
            value={resource && resource.location && resource.location?.value}
            disabled={resource && resource.location && resource.location?.value ? false : true}
            noEditable
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Date of Procedure"}
            value={resource.appointment_date ? moment(resource.appointment_date).format("MM/DD/YYYY") : undefined}
            disabled
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Time of Procedure"}
            value={resource.appointment_date ? moment(resource.appointment_date).format("hh:mm A") : undefined}
            disabled
          />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            isErrorTextSpacing={true}
            label={"Provider"}
            value={resource && resource.provider && resource.provider?.value}
            disabled
          />
        </div>
      </React.Fragment>
    </div>
  );
};

export default Demographics;
